/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

/* Additional Fonts */

@font-face {
  font-family: sweety-donut;
  src: url(assets/fonts/Sweety-Donut.otf) format("opentype");
}

@font-face {
  font-family: 'janesville_script_regular';
  src: url(assets/fonts/janesville_script-webfont.woff2) format('woff2'),
       url(assets/fonts/janesville_script-webfont.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Olive Beret';
  src: url(assets/fonts/Olive_Beret.eot) format("embedded-opentype"),
      url(assets/fonts/Olive_Beret.woff2) format("woff2"),
       url(assets/fonts/Olive_Beret.woff) format("woff"),
       url(assets/fonts/Olive_Beret.svg) format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'The Shallow';
  src: url(assets/fonts/The_Shallow.woff2) format('woff2'),
       url(assets/fonts/The_Shallow.woff) format('woff'),
       url(assets/fonts/The_Shallow.svg) format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Nadia;
  src: url(assets/fonts/Nadia.eot);
  src: url(assets/fonts/Nadia.eot?#iefix) format("embedded-opentype"),
  url(assets/fonts/Nadia.woff2) format("woff2"),
  url(assets/fonts/Nadia.woff) format("woff"),
  url(assets/fonts/Nadia.ttf) format("truetype"),
  url(assets/fonts/Nadia.svg) format("svg");
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family:  "Axis Extrabold";
  src: url(assets/fonts/Axis-Extrabold.otf) format("opentype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Boom Farrow";
  src: url(assets/fonts/Boom-Farrow.eot) format("embedded-opentype");
  src: url(assets/fonts/Boom-Farrow.woff2) format("woff2"),
  url(assets/fonts/Boom-Farrow.woff) format("woff"),
  url(assets/fonts/Boom-Farrow.eot) format("embedded-opentype"),
  url(assets/fonts/Boom-Farrow.svg) format("svg");           
}

@font-face {
  font-family: "Brandon Grotesque Light";
  src: url(assets/fonts/Brandon_light.otf) format("opentype");
 
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Loganberry;
  src: url(assets/fonts/Loganberry.woff2) format("woff2"),
  url(assets/fonts/Loganberry.woff) format("woff"),
  url(assets/fonts/Loganberry.eot) format("embedded-opentype"),
  url(assets/fonts/Loganberry.svg) format("svg");
  font-weight: normal;
  font-style: normal;
}

/* @font-face {
  font-family: "Avenir Next";
  src: url();
} */

.ld-dark-blue-bkgd {
  background-color: #4866a8;
}

.ld-dark-blue {
  color: #4866a8;
}

/* @font-face {
  font-family: Emily Smiles Brush;
  src: url(assets/fonts/Emily\ Smiles\ Brush.eot) format("embedded-opentype"),
        url('assets/fonts/Emily\ Smiles\ Brush.otf') format("opentype")
  /* src: url('assets/fonts/Emily\ Smiles\ Brush.woff') format("woff"); */
  /* src: url(assets/fonts/Emily\ Smiles\ Brush.woff2) format("woff2");
} */

@font-face {
  font-family: 'Playfair Display';
  src: url(assets/fonts/PlayfairDisplay-Black.ttf) format("truetype"),
  url(assets/fonts/PlayfairDisplay-BlackItalic.ttf) format("truetype"),
  url(assets/fonts/PlayfairDisplay-Bold.ttf) format("truetype"),
  url(assets/fonts/PlayfairDisplay-BoldItalic.ttf) format("truetype"),
  url(assets/fonts/PlayfairDisplay-Italic.ttf) format("truetype"),
  url(assets/fonts/PlayfairDisplay-Regular.ttf) format("truetype"),
}
/* 
@font-face {} */


h1, h2, h3, h4, h5, h6 {
  font-family: 'Avenir Next';
}

.olive-beret {
  font-family: 'olive beret';
}

/* Footer position */

* {
  box-sizing: border-box;
 }
 *:before,
 *:after {
  box-sizing: border-box;
 }
 html,
 body {
  min-height: 100%;
  height: auto;
  position: relative;
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 100;
  background-color: #fcfcfc;
 }

 footer {
  position: absolute;
  bottom: 0;
  width: 100%;
 }

/* form additional styling */

.form-extra {
    padding: 4px 10px 10px 10px;
    background-color:rgb(250, 250, 250, 0.75);
}

.form-row {
    margin: 10px 0px 10px 0px;
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin: 0px 4px 0px 4px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {display:none;}
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #a9c6f3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #a9c6f3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .btn-secondary {
    background-color: #4866a8;
}

.whitespace {
  white-space: pre-wrap;
}

.breakword {
  word-break: break-all;
}


.alice {
  font-family: Alice;
}

.janesville {
  font-family: 'janesville_script_regular';
}

.janesville-big {
  font-size: 72px;
  line-height: 50px;
}

.janesville-bigger {
  font-size: 30vw;
}

.mat-progress-spinner circle, .mat-spinner circle  {
  color: #4866a8;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #4866a8;
}

mat-spinner {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn-primary, .btn-secondary {
  border-radius: 10px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.btn-secondary {
background-color: #ffffff !important;
color: #655AAE !important;
border: 2px solid #655AAE !important;
}

.dropdown-menu  {
  width: 60px !important;
  min-width: 60px !important;
}