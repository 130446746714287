

// My variable overrides

//theme colours

// $blue: #a9c6f3;
$blue: #655AAE;
$indigo: #4866a8;
$purple: #e0b8e6;
$pink: #faafc9;
$red: #fb6f8f;
$orange: #ff9d74;
$yellow: #ffe54f;
$white: white;

$font-family-sans-serif: 'Avenir Next';
$font-family-base: 'Avenir Next';

html,
 body {
  height: 100%;
  position: relative;
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 100;
 }

// Bootstrap and its default variables

@import "node_modules/bootstrap/scss/bootstrap";

// @import "node_modules/bootstrap/scss/functions";
// @import "node_modules/bootstrap/scss/variables";
// @import "node_modules/bootstrap/scss/mixins";

//optionals
// // @import "node_modules/bootstrap/scss/reboot";
// @import "node_modules/bootstrap/scss/type";
// // @import "node_modules/bootstrap/scss/images";
// @import "node_modules/bootstrap/scss/code";
// @import "node_modules/bootstrap/scss/grid";

// fonts and such
@import "styles.css";